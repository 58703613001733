<template>
  <base-dropdown
    dropdown-class="mt-2 w-56 rounded-sm shadow-lg z-10 bg-white border border-gray-200"
  >
    <template
      #dropdownToggleBtn="{
        handleDropdownToggle
      }"
    >
      <base-button
        tag="a"
        variant="btn-primary"
        href="javascript:void(0)"
        class="flex items-center mr-1.5 transform -skew-x-25"
        @click="handleDropdownToggle()"
      >
        <template #default>
          <span v-if="currentUser" class="mr-1.5 transform font-semibold skew-x-25 truncate">
            {{ currentUser.name }}
          </span>
          <base-svg
            class="h-3.5 w-3.5 transform skew-x-25 text-white"
            src="icons/arrowDownWide.svg"
            :svg-attributes="{
              class: 'h-full w-full'
            }"
            tag="span"
          />
        </template>
      </base-button>
    </template>
    <template #dropdownContent="{ handleDropdownToggle }">
      <ul class="list-none">
        <li
          v-if="selectedLicenseeName"
          class="flex justify-between items-center px-4 py-2 text-xs "
        >
          <span class="w-full font-base text-primary-gray border border-red-600 p-2 rounded-lg">
            Licensee Name:
            <br>
            <div class="w-full font-semibold text-primary-red truncate">{{ selectedLicenseeName }}</div>
          </span>
        </li>

        <li
          v-if="userLicensees.length > 1 && selectedLicenseeName"
          class="flex justify-between items-center px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
          role="menuitem"
          @click="handleChangeLicensee()"
        >
          <span
            class="w-full"
          >
            Change Licensee
          </span>
        </li>


        <li
          class="flex justify-between items-center px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
          role="menuitem"
          @click="handleLogout(handleDropdownToggle)"
        >
          <span
            class="w-full"
          >
            Logout
          </span>
        </li>
        <router-link
          v-if="!$route.path.includes('brand-assurance')"
          class="flex justify-between items-center px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
          role="menuitem"
          tag="li"
          to="/fact-sheets"
          target="_blank"
        >
          <span
            class="w-full"
          >
            Fact Sheets
          </span>
        </router-link>
        <div class="mx-4 mt-4 mb-2  py-1 font-mono text-center text-10 text-gray-400 bg-gray-100 border border-gray-200 rounded-lg cursor-default">
          Version: {{ appVersion }}
        </div>
      </ul>
    </template>
  </base-dropdown>
  <!-- confirm logout modal -->
  <confirm-logout-modal
    v-model="showLogoutModal"
    @accepted="logout()"
    @rejected="setLogoutModalVisibility(false)"
  />
</template>

<script>
import { ref, defineAsyncComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import useStyleGuideSidebar from '@/components/style-guide-sidebar/styleGuideSidebar.js';
import BaseDropdown from '@/components/generic-components/BaseDropdown.vue';
import BaseButton from '@/components/generic-components/BaseButton.vue';
import BaseSvg from '@/components/generic-components/BaseSvg.vue';

export default {
    name: 'AppLogoutDropDown',

    components: {
        BaseDropdown,
        BaseButton,
        BaseSvg,
        ConfirmLogoutModal: defineAsyncComponent(() => import('./ConfirmLogoutModal.vue'))
    },

    setup () {
        const store = useStore();
        const router = useRouter();
        const { resetSidebarContent } = useStyleGuideSidebar();
        const currentUser = computed(() => store.getters['auth/getUserInfo']);

        const userLicensees = computed(() => store.getters['auth/getUserLicensees'] || []);
        const selectedLicenseeName = computed(() => store.getters['auth/getSelectedLicensee']?.licenseeName || '');

        const handleLogout = (handleDropdownToggle) => {
            // close dropdown
            handleDropdownToggle();
            // open confirm logout modal
            setLogoutModalVisibility(true);
        };
        const showLogoutModal = ref(false);
        const setLogoutModalVisibility = (visibility) => {
            showLogoutModal.value = visibility;
        };
        const logout = async () => {
            // resetSidebarContent();
            await store.dispatch('auth/logoutUser');
            // router.push({ name: 'Login' });
        };

        const handleChangeLicensee = async () => {
            await store.dispatch('auth/setSelectedLicensee', null);
            router.replace({ name: 'PortalSelection' });
        };

        const appVersion = ref(process.env.VUE_APP_PORTAL_VERSION);

        return {
            handleLogout,
            showLogoutModal,
            setLogoutModalVisibility,
            logout,
            currentUser,
            userLicensees,
            selectedLicenseeName,
            handleChangeLicensee,
            appVersion
        };
    }
};
</script>
